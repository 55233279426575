import React from "react";
import "./styles.scss";
import { useField } from "formik";

const CustomSelect = ({
  label,
  className,
  options = [],
  onChange,
  ...rest
}) => {
  const [field, meta] = useField(rest);
  return (
    <div className={`custom-select-wrapper ${className}`}>
      <div className="label">{label}</div>
      <select {...field} {...meta} {...rest} onChange={onChange}>
        {options.length === 0
          ? []
          : options.map((opt) => (
              <option value={opt?.value}>{opt?.label}</option>
            ))}
      </select>
      {meta.error && meta.touched && (
        <p className="error-message">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomSelect;
