import React from "react";
import "./styles.scss";
import { AiFillCheckCircle } from "react-icons/ai";

const SuccessMessage = ({message}) => {
  return (
    <div className="success-message">
      <div className="icon-wrapper">
        <AiFillCheckCircle className="icon" />
      </div>
      <div className="title">{message}</div>
    </div>
  );
};

export default SuccessMessage;
