import React, { useEffect, useState } from "react";
import CustomButton from "../../common/CustomButton";
import { findMessage, Toaster } from "../../../constants";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getTeamMemberFundraisers,
  getTeamMemberInvitationData,
  respondTeammemberInvitation,
} from "../../../apis/Team";
import FundraiserSelectorModal from "../Admin/Dashboard/FundraiserSelectorModal";
import ConfirmationModal from "../../common/ConfirmationModal";

const TeamMemberDashboard = () => {
  const [fundraiserSelectorModal, setFundraiserSelectorModal] = useState(false);

  const [activeModal, setActiveModal] = useState(null);

  const [fundraisers, setFundraisers] = useState(null);

  const [loader, setLoader] = useState(null);

  const auth = useSelector((state) => state.auth.me);

  const fetchAllGroups = async () => {
    const teammemberFundraisers = await getTeamMemberFundraisers();

    setFundraisers(teammemberFundraisers);
  };

  const onModalClose = () => {
    setFundraiserSelectorModal(false);
    setActiveModal(null);
  };

  const handleRespondInvitation = async (response) => {
    setLoader(true);
    try {
      const invitationCode = activeModal?.data?.invitationCode;
      if (!invitationCode) return;
      await respondTeammemberInvitation(invitationCode, response);
      Toaster(
        "success",
        response
          ? "Invitation Accepted successfully!"
          : "Invitation Rejected successfully!"
      );
      setActiveModal(null);
      setLoader(false);
      fetchAllGroups();
    } catch (e) {
      setLoader(false);
      Toaster("error", findMessage(e, "Error responding to invitation!"));
    }
  };

  const location = useLocation();

  useEffect(() => {
    const handleInvitation = async () => {
      const data = await getTeamMemberInvitationData(invitation);
      setActiveModal({
        type: "INVITATION_MODAL",
        data,
      });
    };
    const invitation = new URLSearchParams(location.search).get(
      "invitationCode"
    );
    if (invitation && auth) {
      handleInvitation();
    }
    if (invitation && !localStorage.fundToken) {
      Toaster("error", "Log in and use the link again to check invitation");
    }
  }, [location, auth]);

  useEffect(() => {
    if (auth) fetchAllGroups();
  }, [auth]);

  const navigate = useNavigate();

  const openFundraiserDashboard = (fundraisingId) => {
    onModalClose();
    Toaster("success", "Redirecting to fundraiser...");
    setTimeout(() => {
      navigate("/teammember/dashboard/" + fundraisingId);
    }, 3000);
  };

  return (
    <div className="admin-dashboard-wrapper">
      <ConfirmationModal
        title={"Fundraiser Invitation"}
        prompt={`You have been invited to ${
          activeModal?.data?.fundRaisingId?.fundRaisingName || "Fundraiser"
        }`}
        open={activeModal?.type === "INVITATION_MODAL"}
        onClose={onModalClose}
        onConfirmation={() => handleRespondInvitation(true)}
        onCancel={() => handleRespondInvitation(false)}
        confirmationText="Accept"
        cancelText="Reject"
        loader={loader}
      />
      <FundraiserSelectorModal
        open={fundraiserSelectorModal}
        onClose={onModalClose}
        fundraiserData={fundraisers || []}
        onSubmit={openFundraiserDashboard}
      />
      <div className="title">Teammember Dashboard</div>

      <div className="stats-wrapper-centered">
        <div className="stat-one-wrapper section">
          <div className="stat-title">Information</div>
          <div className="stat">
            Name:
            <span>{auth?.firstName || "---"}</span>
            <span>{auth?.lastName || "---"}</span>
          </div>
          <div className="stat">
            Email: <span>{auth?.email || "---"}</span>
          </div>
          <div className="stat">
            Contact: <span>{auth?.phoneNo || "---"}</span>
          </div>
        </div>
      </div>
      <div className="secondary-admin-buttons">
        <CustomButton
          className="customBtn"
          title="Fundraisers"
          disabled={!fundraisers}
          onClick={() => {
            if (fundraisers.length === 0)
              Toaster("warning", "You are not part of any fundraiser!");
            else setFundraiserSelectorModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default TeamMemberDashboard;
