const TermsAndConditions = () => {
  return (
    <div className="privacy-policy">
      <h1>Top Score Fundraising Terms and Conditions</h1>
      <h2>Introduction</h2>
      <p>
        These Terms and Conditions ("Terms") govern your use of the "Top Score Fundraising" platform provided by Top Score Fundraising (the "Company", "we", "us", or "our"). By accessing or using the Service, you agree to be bound by these Terms.
      </p>
      <h2>Use of the Service</h2>
      <p>
        You must use the Service in compliance with these Terms and all applicable laws. You are responsible for your conduct and any content you provide to the Service.
      </p>
      <h2>User Accounts</h2>
      <p>
        To use certain features of the Service, you may need to create an account. You are responsible for maintaining the confidentiality of your account credentials and for any activities that occur under your account.
      </p>
      <h2>Donations and Payments</h2>
      <p>
        All donations made through the Service are final and non-refundable, except as required by law. We use third-party payment processors to handle transactions.
      </p>
      <h2>Intellectual Property</h2>
      <p>
        The Service and its content are protected by intellectual property laws. You may not use, copy, or distribute any content from the Service without our prior written consent.
      </p>
      <h2>Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by law, Top Score Fundraising will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly.
      </p>
      <h2>Termination</h2>
      <p>
        We may suspend or terminate your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
      </p>
      <h2>Governing Law</h2>
      <p>
        These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising from or relating to these Terms will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
      </p>
      <h2>Changes to these Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service after any such changes constitutes your acceptance of the new Terms.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms, please contact us at [Your Contact Information] for further assistance.
      </p>
      <h2>Effective Date</h2>
      <p>
        These Terms are effective as of December 16, 2025.
      </p>
    </div>
  );
};

export default TermsAndConditions;