import { TableCell } from "@mui/material";
import React from "react";

const CustomColumnCell = (props) => {
  return (
    <TableCell className="custom-column-cell-wrapper" align="center" {...props}>
      {props.children}
    </TableCell>
  );
};

export default CustomColumnCell;
