import axios from "../AxiosInstance";

export const adminSignUp = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("/adminRoutes/adminSignUp", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getPendingApprovalAdmins = () =>
  new Promise((resolve, reject) => {
    axios
      .get("/adminRoutes/secondaryAdminApprovals")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getPendingApprovalParticipants = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/adminRoutes/getParticipantRequests?fundRaisingId=${fundRaisingId}`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getApprovedAdmins = () =>
  new Promise((resolve, reject) => {
    axios
      .get("/adminRoutes/getApprovedAdmins")
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const approveAdmin = (adminId) =>
  new Promise((resolve, reject) => {
    axios
      .put("/adminRoutes/approveSecondaryAdmin", { adminId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const approveParticipant = (requestId) =>
  new Promise((resolve, reject) => {
    axios
      .put("/adminRoutes/approveParticipant", { requestId })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const inviteAdmin = (body) =>
  new Promise((resolve, reject) => {
    if (!body.privileges)
      body.privileges = {
        fullAccess: false,
        startNewFundraiser: false,
        acceptStudentFund: false,
        sendEmailAndTexts: false,
        changeGoalsAndCause: false,
        addNewAdministrator: false,
      };
    axios
      .post("/adminRoutes/generatesecondaryAdminInvitationLink", body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updatePrivileges = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put("/adminRoutes/updateSecondayAdminPrivileges", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getDashboard = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(
        `/adminRoutes/getAdminDashboardByFundRaisingId?fundRaisingId=${fundRaisingId}`
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const GetPrimaryAdminDashboard = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/adminRoutes/getPrimaryAdminDashboard`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const GetAdminFundRaisings = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`/adminRoutes/getAdminFundRaisings`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const GetSecondaryAdmins = () =>
  new Promise((resolve, reject) => {
    axios
      .post(`/adminRoutes/getSecondaryAdminEmails`)
      .then((response) => {
        //console.log(response);
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const generateParticipantInvitationLink = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`/adminRoutes/generateParticipantInvitationLink`, body)
      .then((response) => {
        //console.log(response);
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getAdminStatus = () =>
  new Promise((resolve, reject) => {
    axios
      .get(`/adminRoutes/getAdminStatus`)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addManualFundsToParticipant = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post(`adminRoutes/addManualDonation`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
