import React from "react";
import ".././Home/home.scss";
import { FaSchool, FaUserGraduate } from "react-icons/fa";
import supportProfile from "../../../assets/supportprofile.png";
import CustomInput from "../../common/CustomInput";
import { Form, Formik } from "formik";
import CustomButton from "../../common/CustomButton";
import CustomSelect from "../../common/CustomSelect";
import CustomTextArea from "../../common/CustomTextArea";

function Supports() {
  const stakeholders = [
    {
      title: "Phone",
      Icon: FaUserGraduate,
      para: "(405) 353 - 2540",
    },
    {
      title: "Mail",
      Icon: FaSchool,
      para: "30 N Gould S Ste. 4000 Sheridan, WY 82801",
    },
  ];
  return (
    <>
      <div className="support-1stsection-wrapper">
        <p className="supports-title">Support</p>
        <div className="menu-support">
          {stakeholders.map(({ Icon, title, para }) => (
            <div className="card-support">
              <Icon className="icon-support " />
              <h2 className="header-support">{title}</h2>
              <p className="paragraph-support">{para}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="support-2nd-section-wrapper">
        <div className="support-img-wrapper">
          <img src={supportProfile} className="support-profile-img" />
        </div>
        <div className="support-input-wrapper">
          <Formik
            initialValues={{
              firstname: "firstname",
              emails: "email",
              issue: "issues",
              question: "questions",
            }}
          >
            <Form>
              <CustomInput
                label={"First Name"}
                name="name"
                placeholder="Johnson Wade"
              />

              <CustomInput
                label={"Email"}
                name="email"
                placeholder="Johnsonwade@hotmail.com"
              />

              <CustomSelect
                label={"Issues"}
                name="issues"
                placeholder="Please select a fundraiser"
              />

              <CustomTextArea label={"Question"} name="questions" />

              <CustomButton title={"Submit"} className="support-form-button" />
            </Form>
          </Formik>
        </div>
      </div>
    </>
  );
}

export default Supports;
