import { TableCell } from "@mui/material";
import React from "react";

const CustomCell = (props) => {
  return (
    <TableCell className="custom-cell-wrapper" align="center" {...props}>
      {props.children}
    </TableCell>
  );
};

export default CustomCell;
