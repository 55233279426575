import React, { useEffect, useState } from "react";
import "./styles.scss";
import CustomButton from "../../common/CustomButton";
import CustomInput from "../../common/CustomInput";
import { Grid } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CustomCheckBox from "../../common/CustomCheckBox";
import ReCaptchaV2 from "react-google-recaptcha";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import Spinner from "../../common/Spinner";
import { Toaster } from "../../../constants";
import { TeamSignUpValidation } from "../../../constants/validate";
import LoginModal from "./LoginModal";
import { logIn } from "../../../apis/Auth";
import {
  getTeamMemberInvitationData,
  teamMemberSignUp,
} from "../../../apis/Team";
import TokenImg from "../../../assets/tokenImg.png";
import { getGroups } from "../../../apis/Group";
import CustomRadioForm from "../../common/CustomRadioForm";
import CustomPhone from "../../common/CustomPhone";

const radios = [
  {
    name: "Yes",
    value: "yes",
  },
  {
    name: "No",
    value: "no",
  },
];

const TeamMemberSignUp = () => {
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const [signUpLoader, setSignUpLoader] = useState(false);
  const [activeRadio, setActiveRadio] = useState("no");
  const [teammemberData, setTeammemberData] = useState(null);

  const navigate = useNavigate();

  const { invitationCode } = useParams();

  const isPublicInvitation = invitationCode?.startsWith("pub_");

  const handleRadioChange = (e) => setActiveRadio(e.target.value);

  const handleCloseLoginForm = () => setOpenLoginForm(false);

  const handleTeamSignUp = async (values, actions) => {
    try {
      setSignUpLoader(true);

      let body = {
        ...values,
        confirmEmail: values.email,
        isGuardian: activeRadio,
        fundRaisingName: isPublicInvitation
          ? null
          : teammemberData.fundRaisingId.fundRaisingName,
        verificationSelection: "phone",
        invitationCode: isPublicInvitation
          ? invitationCode
          : teammemberData.invitationCode,
      };
      if (invitationCode) {
        body = { ...body, invitationCode };
      }
      let response = await teamMemberSignUp(body);

      Toaster(
        "success",
        response?.message || "An OTP code has been sent to you"
      );

      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values.email,
          role: "teamMember",
          mode: "signUp",
          phone: values.phoneNo,
          redirect: "/teammember/dashboard",
        },
      });
      actions.setValues({
        firstName: "",
        lastName: "",
        email: "",
        confirmEmail: "",
        phoneNo: "",
        password: "",
        confirmPassword: "",
        checks: {
          isAuth: false,
          isPrivacyPolicy: false,
          isTerms: false,
        },
      });

      setSignUpLoader(false);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "OTP code could not be sent";
      Toaster("error", errorMessage);
      setSignUpLoader(false);
    }
  };

  const handleLogin = async (values) => {
    try {
      let response = await logIn(
        values.email,
        values.password,
        "teamMember",
        "phone"
      );

      Toaster(
        "success",
        response?.message || "An OTP code has been sent to you"
      );

      navigate("/auth/verify", {
        state: {
          verificationSelection: "phone",
          email: values?.email,
          role: "teamMember",
          mode: "logIn",
          phone: response?.data?.phoneNo,
          redirect: "/teammember/dashboard",
        },
      });
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Teammember could not login";
      Toaster("error", errorMessage);
    }
  };

  const handleCheckBoxChange = (setValues, values, name, value) => {
    setValues({ ...values, checks: { ...values.checks, [name]: value } });
  };

  const handleGetTeammemberData = async () => {
    if (!invitationCode || invitationCode.startsWith("pub_")) return;
    try {
      const data = await getTeamMemberInvitationData(invitationCode);
      if (data.expire) return Toaster("warning", "Invitation code expired!");
      setTeammemberData(data);
    } catch (e) {
      Toaster(
        "error",
        e?.response?.data?.message || "Error fetching invitation data!"
      );
    }
  };

  useEffect(() => {
    handleGetTeammemberData();
  }, [invitationCode]);

  return (
    <>
      <div className="teammember-signup-wrapper">
        <div className="upper-section">
          <h1 className="heading">TeamMemberSignup</h1>
        </div>
        <div className="lower-section">
          <div className="existing-button">
            <CustomButton
              title={"I have an existing account"}
              onClick={() => setOpenLoginForm(true)}
            />
          </div>
          {!teammemberData && !isPublicInvitation ? (
            <div className="loader">
              <Spinner />
              <p className="info">Loading invitation data...</p>
            </div>
          ) : (
            <div>
              <div className="choose-guardian-wrapper">
                {/* <div className="is-guardian">Are you a guardian?</div> */}
                {/* <CustomRadioForm
                  title={"Are you a guardian?"}
                  value={activeRadio}
                  onChange={(event) => {
                    handleRadioChange(event);
                  }}
                  radios={radios}
                  className={"radio"}
                /> */}
                <div className="basic-radio-form">
                  <div className="radio-title">Are you a guardian?</div>
                  <div className="radio-group">
                    {radios.map((item, index) => (
                      <label key={index} className="radio-label">
                        <input
                          type="radio"
                          value={item.value}
                          checked={activeRadio === item.value}
                          onChange={handleRadioChange}
                          className="radio-input"
                        />
                        <span className="radio-text">{item.name}</span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              <Formik
                initialValues={
                  !isPublicInvitation
                    ? {
                        firstName: teammemberData.firstName,
                        lastName: teammemberData.lastName,
                        email: teammemberData.email,
                        guardianEmail: "",
                        fundRaisingName:
                          teammemberData.fundRaisingId.fundRaisingName,
                        phoneNo: teammemberData.phoneNumber,
                        password: "",
                        confirmPassword: "",
                        checks: {
                          isAuth: false,
                          isPrivacyPolicy: false,
                          isTerms: false,
                        },
                      }
                    : {
                        firstName: "",
                        lastName: "",
                        email: "",
                        guardianEmail: "",
                        phoneNo: "",
                        password: "",
                        confirmPassword: "",
                        checks: {
                          isAuth: false,
                          isPrivacyPolicy: false,
                          isTerms: false,
                        },
                      }
                }
                enableReinitialize
                validationSchema={TeamSignUpValidation}
                validateOnChange={true}
                validateOnBlur={false}
                onSubmit={(values, actions) =>
                  handleTeamSignUp(values, actions)
                }
              >
                {({ setValues, values, errors }) => {
                  return (
                    <Form>
                      <div className="signup-form">
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomInput
                              label={"First Name"}
                              type={"text"}
                              name="firstName"
                              placeholder="Johnson Wade"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomInput
                              label={"Last Name"}
                              type={"text"}
                              name="lastName"
                              placeholder="Johnson Wade"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomInput
                              type="email"
                              label={"Email"}
                              name="email"
                              placeholder="JohnsonWade@gmail.com"
                              disabled={!isPublicInvitation}
                            />
                          </Grid>
                          {!isPublicInvitation && (
                            <Grid item xs={12} sm={6} md={4}>
                              <CustomInput
                                label={"Fundraising Name"}
                                name="fundRaisingName"
                                disabled
                              />
                            </Grid>
                          )}
                          {activeRadio === "yes" && (
                            <Grid item xs={12} sm={6} md={4}>
                              <CustomInput
                                type="email"
                                label={"Guardian Email"}
                                name="guardianEmail"
                                placeholder="JohnsonWade@gmail.com"
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6} md={4}>
                            <CustomPhone
                              label={"Phone Number"}
                              name="phoneNo"
                              placeholder={"+11234567890"}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomInput
                              type="password"
                              label={"Password"}
                              name="password"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <CustomInput
                              type="password"
                              label={"Re-enter password"}
                              name="confirmPassword"
                            />
                          </Grid>
                        </Grid>
                      </div>
                      <div className="condition-list">
                        <FormGroup>
                          <FormControlLabel
                            typeof="checkbox"
                            checked={values.checks.isAuth}
                            onChange={(event) =>
                              handleCheckBoxChange(
                                setValues,
                                values,
                                "isAuth",
                                event.target.checked
                              )
                            }
                            required
                            control={<CustomCheckBox />}
                            label={
                              <p className="para">
                                I am an authorized administrator of the
                                school/organization
                              </p>
                            }
                          />
                          <FormControlLabel
                            checked={values.checks.isPrivacyPolicy}
                            onChange={(event) =>
                              handleCheckBoxChange(
                                setValues,
                                values,
                                "isPrivacyPolicy",
                                event.target.checked
                              )
                            }
                            typeof="checkbox"
                            required
                            control={<CustomCheckBox />}
                            label={
                              <p className="para">
                                I have read & agree to
                                <span className="link">
                                  Terms and condition
                                </span>
                              </p>
                            }
                          />
                          <FormControlLabel
                            checked={values.checks.isTerms}
                            onChange={(event) =>
                              handleCheckBoxChange(
                                setValues,
                                values,
                                "isTerms",
                                event.target.checked
                              )
                            }
                            typeof="checkbox"
                            required
                            control={<CustomCheckBox />}
                            label={
                              <p className="para">
                                I have read & agree to
                                <span className="link">Privacy and policy</span>
                              </p>
                            }
                          />
                        </FormGroup>
                      </div>
                      <div>
                        <ReCaptchaV2
                          theme="dark"
                          sitekey={"6LfHILklAAAAAEQP0zw8P2sWvkI-X2GJ-duOldlK"}
                          className="recaptcha-container"
                          size="normal"
                        />
                      </div>
                      {signUpLoader ? (
                        <Spinner />
                      ) : (
                        <CustomButton
                          title={"Submit"}
                          className={"submit-button"}
                          type={"submit"}
                          disabled={Object.keys(errors).length > 0}
                        />
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>

        <div className="border-line" />
        <div className="token-btn-wrapper">
          <div className="token-img-wrapper">
            <img src={TokenImg} alt="token-img" className="token-img" />
          </div>
          <div className="token-second-wrapper">
            <div className="token-buttons-wrapper">
              <div className="token-heading-wrapper">
                <h2 className="token-heading">2.50$ = token 1</h2>
              </div>
              <div>
                <CustomButton title={"10$"} className="token-btn" />
                <CustomButton title={"15$"} className="token-btn" />
                <CustomButton title={"25$"} className="token-btn" />
                <CustomButton title={"50$"} className="token-btn" />
                <CustomButton title={"75$"} className="token-btn" />
                <CustomButton title={"100$"} className="token-btn" />

                <CustomButton title={"Custom"} className="custom-btn" />
                <CustomButton title={"Pay now"} className="custom-btn" />
              </div>
            </div>
          </div>
        </div>
        <div className="border-line" />
        <div className="payment-btn-wrapper">
          <CustomButton title={"Payments"} className="payment-btn" />
        </div>
      </div>
      <LoginModal
        open={openLoginForm}
        onClose={handleCloseLoginForm}
        onLogin={handleLogin}
        title="TeamMember"
      />
    </>
  );
};

export default TeamMemberSignUp;
