import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("fundToken");

const instance = axios.create({ baseURL });

export default instance;
