import React from "react";
import "./styles.scss";
import { useField } from "formik";

const CustomTextArea = ({ label, className, onChange, ...rest }) => {
  const [field, meta] = useField(rest);
  return (
    <div className={`custom-textarea-wrapper ${className}`}>
      <div className="label">{label}</div>
      <textarea {...rest} {...field} />
      {meta.error && meta.touched && (
        <p className="error-message">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomTextArea;