import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";

const SideBar = ({ anchor, open, menuItems, items, handleClose, menuName }) => {
  const navigate = useNavigate();
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={handleClose}
      className="side-bar-drawer"
    >
      <Box sx={{ width: 250 }}>
        <List>
          <ListItem key={"accordian"} disablePadding>
            <Accordion style={{ width: "100%" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className="icon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{menuName}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {menuItems.map((item) => (
                    <ListItem
                      onClick={() => {
                        navigate(item.path);
                        handleClose();
                      }}
                    >
                      {item.title}
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </ListItem>
          {items.map(({ Icon, title, path }, index) => {
            return (
              <ListItem
                key={title}
                disablePadding
                onClick={() => {
                  navigate(path);
                  handleClose();
                }}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon className="icon" />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
