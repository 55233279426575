import React from "react";
import "../Home/home.scss";
import AboutImg from "../../../assets/aboutImg.png";

function About() {
  return (
    <div className="about-wrapper">
      <div>
        <h2 className="about-first-heading">Mission</h2>
        <p className="about-first-para">
          "We strive to maximize the benefits to each organization we serve by
          creating interactive fundraising games. We are constantly seeking to
          provide the tools necessary to increase participation and
          entertainment."
        </p>
      </div>
      <div className="about-2nd-section-wrapper">
        <div className="about-img-wrapper">
          <img src={AboutImg} className="about-img" />
        </div>
        <div className="about-para-wrapper">
          <p className="about-2nd-para">
            "With so many things vying for our attention these days we at Top
            Score Fundraising are modernizing the fundraising model. We want to
            help people gain the funding they need in order let their cause
            thrive. By giving more back to the organization less effort is
            needed to raise funds."
            <h2 className="about-first-heading">- Carson Spencer , President</h2>
          </p>
          
        </div>
      </div>
      <div>
        <h2 className="about-first-heading">How It Works</h2>
        <p className="about-3nd-para">
          Top Score Fundraising uses a unique blend of traditional fundraising
          with online games to help motivate kids to raise funds. We have
          developed a platform in which administrators can create an account for
          their organizations and send out emails to students inviting them to
          participate. Students will create accounts and send out emails and
          texts to family and friends requesting donations. They will also be
          given a personal OR code that people can scan in order to donate. Cash
          or Checks may be turned into the administrator. Donations will go into
          the organization's fundraisina account and the student will be given a
          certain amount of tokens for each dollar they raise. Tokens will then
          be used to play games in order to win cash. They can earn achievements
          through plying games or collecting donations that will give them
          additional cash. students may ask donors to join their team and play
          along. If a team member wins both the team member and the student get
          prizes.
        </p>
      </div>
    </div>
  );
}

export default About;
