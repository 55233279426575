import React from "react";
import "./styles.scss";
import { useField } from "formik";

const CustomInput = ({ label, className, onChange, prefix, ...rest }) => {
  const [field, meta, helpers] = useField(rest);

  const handleChange = (e) => {
    helpers.setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={`custom-input-wrapper ${className}`}>
      <div className="label">{label}</div>
      <input {...rest} {...field} onChange={handleChange} />
      {meta.error && meta.touched && (
        <p className="error-message">{meta.error}</p>
      )}
    </div>
  );
};

export default CustomInput;
