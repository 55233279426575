import React, { useEffect } from "react";
import "./styles.scss";
import Logo from "../../assets/Logo.png";
import Logo2 from "../../assets/transparentLogo.png";

import CustomButton from "./CustomButton";
import UserAccount from "./UserAccount";
import { AiOutlineMenu } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAuth } from "../../slices/auth";
import { Toaster } from "../../constants";
import { isLoggedIn } from "../../apis/Auth";

const Navbar = ({
  handleLeftSideBar,
  openLeftSideBar,
  handleRightBar,
  openRightSideBar,
}) => {
  const location = useLocation();
  const path = location.pathname.split("/");
  const currentPage = path[path.length - 1];
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth.me);

  const logout = () => {
    dispatch(clearAuth());
    navigate("/");
    Toaster("success", "You logged out!");
  };

  //hide in case of gameplay
  if (path.includes("play") || path.includes("practice")) return null;
  return (
    <div className="navbar-container">
      <div className="desktop-navbar-wrapper">
        <div className="logo">
          <img src={Logo} alt="" />
        </div>
        <div className="right-section">
          <div className="upper-section">
            <h2 className="header">Gamifying Your Fundraiser</h2>
            <div className="buttons-wrapper">
              {!auth?.role && (
                <CustomButton
                  title={"Sign up"}
                  className={"signins"}
                  onClick={() => navigate("/signup")}
                />
              )}
              {!auth?.role && (
                <CustomButton
                  title={"Login"}
                  className={"signins"}
                  onClick={() => navigate("/login")}
                />
              )}
              {auth?.role && (
                <CustomButton
                  title={"Dashboard"}
                  className={"signins"}
                  onClick={() => navigate("/dashboard")}
                />
              )}
              {auth?.role && (
                <CustomButton
                  title={"Logout"}
                  className={"signins"}
                  onClick={logout}
                />
              )}
            </div>
          </div>
          <div className="lower-section">
            <div className="menu-items">
              <ul>
                <Link to="/home" style={{ textDecoration: "none" }}>
                  <li
                    style={{
                      color:
                        currentPage === "home" || currentPage === ""
                          ? "#E9C451"
                          : "",
                    }}
                  >
                    Home
                  </li>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <li
                    style={{ color: currentPage === "about" ? "#E9C451" : "" }}
                  >
                    About
                  </li>
                </Link>
                <Link to="/games" style={{ textDecoration: "none" }}>
                  <li
                    style={{ color: currentPage === "game" ? "#E9C451" : "" }}
                  >
                    Games
                  </li>
                </Link>
                <Link to="/support" style={{ textDecoration: "none" }}>
                  <li
                    style={{
                      color: currentPage === "support" ? "#E9C451" : "",
                    }}
                  >
                    Support
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-navbar-wrapper">
        <div
          className="menu-icon"
          onClick={() => handleLeftSideBar(!openLeftSideBar)}
        >
          <AiOutlineMenu fontSize={25} />
        </div>
        <div className="logo">
          <img src={Logo2} alt="" />
        </div>
        <UserAccount
          className={"mobile-user-account"}
          onClick={() => handleRightBar(!openRightSideBar)}
        />
      </div>
    </div>
  );
};

export default Navbar;
