import axios from "../AxiosInstance";

export const createGroup = (body) =>
  new Promise((resolve, reject) => {
    axios
      .post("/groupRoutes/createGroup", body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getGroups = (fundRaisingId) =>
  new Promise((resolve, reject) => {
    axios
      .get(`/groupRoutes/getGroup?fundRaisingId=${fundRaisingId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteGroup = (groupId) =>
  new Promise((resolve, reject) => {
    axios
      .delete(`/groupRoutes/deleteGroup?groupId=${groupId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const assignParticipantToGroup = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/groupRoutes/assignParticipantToGroup`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateGroup = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/groupRoutes/updateGroup`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateGroupAdmin = (body) =>
  new Promise((resolve, reject) => {
    axios
      .put(`/groupRoutes/updateGroupAdmin`, body)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
