import React from "react";
import Logo from "../../assets/transparentLogo.png";
import { AiOutlineMail } from "react-icons/ai";
import { FiPhoneCall } from "react-icons/fi";
import App from "../../assets/appstore.png";
import Google from "../../assets/googlestore.png";
import { FaLinkedinIn, FaFacebookF } from "react-icons/fa";
import {
  AiFillYoutube,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  const path = location.pathname.split("/");

  // Hide in case of gameplay
  if (path.includes("play") || path.includes("practice")) return null;
  return (
    <footer className="footer-wrapper">
      <div className="upper-middle-wrapper">
        <div className="upper-section">
          <div className="first-section">
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
            <h2 className="contact-header">Contact Us</h2>
            <div className="contact-item">
              <div className="header">
                <AiOutlineMail /> <span className="name">Email</span>
              </div>
              <p>
                <a href="mailto:support@topscorefundraising.com">
                  support@topscorefundraising.com
                </a>
              </p>
            </div>
            <div className="contact-item">
              <div className="header">
                <FiPhoneCall /> <span className="name">Call Us</span>
              </div>
              <p>
                <a href="tel:+12029182222">+1 202-918-2222</a>
              </p>
            </div>
          </div>
          <div className="second-third-wrapper">
            <div className="section">
              <div className="header">Quick Links</div>
              <div className="menu">
                <ul>
                  <li><Link to="/about" style={{ color: 'black' }}>About</Link></li>
                  <li><Link to="/games" style={{ color: 'black' }}>Games</Link></li>
                  <li><Link to="/support" style={{ color: 'black' }}>Support</Link></li>
                  <li><Link to="/privacy-policy" style={{ color: 'black' }}>Privacy Policy</Link></li>
                  <li><Link to="/terms-and-conditions" style={{ color: 'black' }}>Terms and Conditions</Link></li>
                </ul>
              </div>
            </div>
            <div className="section">
              <div className="header">Explore</div>
              <div className="menu">
                <ul>
                  <li><Link to="/about" style={{ color: 'black' }} >About</Link></li>
                  <li><Link to="/games" style={{ color: 'black' }} >Games</Link></li>
                  <li><Link to="/support" style={{ color: 'black' }} >Support</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-section">
          <div className="first-section">
            <h2>Download App</h2>
            <div className="app-store">
              <div className="image-wrapper">
                <img src={App} alt="App Store" />
              </div>
              <div className="image-wrapper">
                <img src={Google} alt="Google Play" />
              </div>
            </div>
          </div>
          <div className="second-section">
            <h2>Follow us on social media</h2>
            <div className="social-lists">
              {[
                FaLinkedinIn,
                AiFillYoutube,
                FaFacebookF,
                AiOutlineTwitter,
                AiFillInstagram,
              ].map((Icon, index) => {
                return (
                  <div className="icon-wrapper" key={index}>
                    <Icon className="icon" />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="third-section">
            <h2>Subscribe to our newsletter</h2>
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Please enter your email"
                className="input"
              />
              <button className="subscribe-button">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-section">
        <div className="rights-reserved">
          © 2022 All rights reserved. Reliance Retail Ltd.
        </div>
      </div>
      <div className="ball-wrapper">
        <div className="ball"></div>
      </div>
    </footer>
  );
};

export default Footer;
