const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Top Score Fundraising Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        This Privacy Policy describes how Top Score Fundraising (the "Company", "we", "us", or "our") collects, uses, and discloses your personal information when you use our platform to donate or manage fundraising campaigns (the "Service").
      </p>
      <h2>Information We Collect</h2>
      <p>
        We collect several types of information to provide and improve our Service:
      </p>
      <ul>
        <li>
          <b>Information you provide directly: </b>
          This includes your name, email address, phone number (optional), and any other information you choose to share when creating an account, donating to a campaign, or contacting us.
        </li>
        <li>
          <b>Donation information: </b>
          We may collect information about your donations, such as the amount donated, the campaign you supported, and your payment method (without full credit card details).
        </li>
        <li>
          <b>Usage data: </b>
          We collect information about how you use our Service, such as the pages you visit, the campaigns you browse, and the features you use.
        </li>
      </ul>
      <h2>How We Use Your Information</h2>
      <p>We use the information we collect for several purposes:</p>
      <ul>
        <li>To process your donations and manage fundraising campaigns.</li>
        <li>
          To communicate with you about your donations, campaigns you support, and updates from Top Score Fundraising.
        </li>
        <li>To personalize your experience and improve the Service.</li>
        <li>To analyze trends and usage patterns to improve the Service.</li>
        <li>To comply with legal and regulatory requirements.</li>
      </ul>
      <h2>Disclosure of Your Information</h2>
      <p>
        We may disclose your information to third-party service providers who help us operate the Service. These service providers are contractually obligated to keep your information confidential and secure. We may also disclose your information if required by law or to protect the rights, property, or safety of Top Score Fundraising or others.
      </p>
      <h2>Your Choices</h2>
      <p>You have choices regarding your information:</p>
      <ul>
        <li>You can access and update your account information at any time.</li>
        <li>You can opt out of receiving marketing communications from us.</li>
        <li>
          You can request that we delete your information (subject to certain legal and regulatory restrictions).
        </li>
      </ul>
      <h2>Data Security</h2>
      <p>
        We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no internet transmission or electronic storage is completely secure.
      </p>
      <h2>Children's Privacy</h2>
      <p>
        Our Service is not directed to children under 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us at [Your Contact Information].
      </p>
      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at [Your Contact Information] for further assistance.
      </p>
      <h2>Effective Date</h2>
      <p>
        This Privacy Policy is effective as of December 16, 2025.
      </p>
      <p>
        <b>Note:</b> This is a sample privacy policy and may need to be modified to comply with specific laws and regulations in your jurisdiction. You may want to consult with an attorney to ensure your privacy policy is compliant.
      </p>
    </div>
  );
};

export default PrivacyPolicy;