import { Form, Formik } from "formik";
import React, {  useState } from "react";
import CustomInput from "../../common/CustomInput";
import CustomButton from "../../common/CustomButton";
import * as Yup from "yup";
import Spinner from "../../common/Spinner";
import { useNavigate } from "react-router-dom";
import {
  changePassword,
} from "../../../apis/Auth";
import { Toaster } from "../../../constants";
import SuccessMessage from "../../common/SuccessMessage";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth.me);
  const email=auth?.email;
  const role=auth?.role;

  if(!email || !role)
  return <div>Please Login to change password!</div>;

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required*")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        " Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
      )
      .trim(),
    confirmPassword: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must be at least 8 characters long and contain at least one letter, one number and a special Character."
      )
      .trim()
      .required("Confirm password is required*")
      .oneOf([Yup.ref("password"), null], "Passwords do not match"),
  });

  const handleFormSubmit = async (values, actions) => {
    try {
      setLoader(true);
      await changePassword(
        email,
        values.password,
        role
      );
      setSuccess(true);
      actions.setValues({
        password: "",
        confirmPassword: "",
      });
      setLoader(false);
    } catch (error) {
      let errorMessage =
        error.response?.data?.message || "Password could not be changed";
      Toaster("error", errorMessage);
      setLoader(false);
    }
  };


  return (
    <div className="change-password-wrapper">
      {loader ? (
        <Spinner />
      ) : success ? (
        <SuccessMessage message="Password has been successfully changed!" />
      ) : (
        <div className="container">
          <div className="title">Change Password</div>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, values }) => (
              <Form>
                <CustomInput
                  label={"New Password"}
                  type={"password"}
                  name="password"
                  className={"input"}
                />
                <CustomInput
                  label={"Confirm password"}
                  type={"password"}
                  name="confirmPassword"
                  className={"input"}
                />
                <div className="save-button-wrapper">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <CustomButton
                      disabled={
                        values.password === "" || values.confirmPassword === ""
                      }
                      title={"Save"}
                      className={"save-button"}
                      onClick={handleSubmit}
                    />
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
};

export default ChangePassword;
