import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomCell from "./CustomCell";
import CustomColumnCell from "./CustomColumnCell";
import CustomCheckBox from "./CustomCheckBox";
import { AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { IoCheckmark } from "react-icons/io5";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useEffect, useState } from "react";
import Spinner from "./Spinner";

const CustomTable = ({
  columns = [{ name: "Name", dataIndex: "name", prefix: null }],
  rows = [],
  getCheckedRows,
  maxHeight = 220,
  loader = false,
  isApprovals = false,
  isCheckBox = false,
  isSelection = false,
  onSelect = (index) => {},
  isActions = false,
  isEdit = true,
  isDelete = true,
  onEdit,
  onDelete,
  onApprove,
  onReject,
  emptyDisplayText = "There are no records to display",
  searchBar = false, // New prop for showing search bar
}) => {
  const [checked, setChecked] = useState([]);
  const [selection, setSelection] = useState(null);
  const [filteredRows, setFilteredRows] = useState(rows); // State for filtered rows
  const [searchValue, setSearchValue] = useState(""); // State for search input

  const handleCheck = (event, index) => {
    let value = event.target.checked;
    let tempArray = [...checked];
    tempArray[index].isChecked = value;
    setChecked(tempArray);

    if (getCheckedRows) {
      tempArray =
        tempArray.length === 0
          ? []
          : tempArray.filter((item) => item.isChecked);
      getCheckedRows(tempArray);
    }
  };

  const handleSelection = (index) => {
    setSelection(index);
    onSelect(index);
  };

  const handleColumnCheck = (event) => {
    let tempArray = [...checked];
    let value = event.target.checked;

    tempArray =
      tempArray.length === 0
        ? []
        : tempArray.map((item) => {
            return { ...item, isChecked: value };
          });
    setChecked(tempArray);

    if (getCheckedRows) {
      tempArray =
        tempArray.length === 0
          ? []
          : tempArray.filter((item) => item.isChecked);
      getCheckedRows(tempArray);
    }
  };

  const displayRow = (data, index) => {
    let row = Object.entries(data);
    let tempRows = [...columns];

    row.forEach(([key, value]) => {
      let columnIndex = columns.map((col) => col?.dataIndex).indexOf(key);
      if (columnIndex > -1) {
        value = (columns[columnIndex].prefix || "") + value;
        tempRows[columnIndex] = { key, value };
      }
    });

    return (
      <>
        {isCheckBox && (
          <CustomCell padding="checkbox">
            <CustomCheckBox
              checked={checked[index]?.isChecked}
              color="primary"
              onChange={(event) => handleCheck(event, index)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </CustomCell>
        )}
        {isSelection && (
          <CustomCell padding="checkbox">
            <CustomCheckBox
              checked={index === selection}
              color="primary"
              onChange={() => handleSelection(index)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </CustomCell>
        )}
        {tempRows.length > 0 &&
          tempRows.map((row) => {
            return (
              <>
                <CustomCell>{row?.value}</CustomCell>
              </>
            );
          })}
        {isActions && (
          <CustomCell>
            <div className="table-action-button-wrapper">
              {isEdit && (
                <div
                  className="icon-wrapper"
                  onClick={() => onEdit && onEdit(data)}
                >
                  <AiOutlineEdit className="icon" />
                </div>
              )}
              {isDelete && (
                <div
                  className="icon-wrapper"
                  onClick={() => onDelete && onDelete(data)}
                >
                  <AiOutlineDelete className="icon" />
                </div>
              )}
            </div>
          </CustomCell>
        )}
        {isApprovals && (
          <CustomCell>
            <div className="table-action-button-wrapper">
              <div
                className="icon-wrapper"
                onClick={() => onApprove && onApprove(data)}
              >
                <IoCheckmark className="icon" />
              </div>
            </div>
          </CustomCell>
        )}
      </>
    );
  };

  const addCheckInRows = (rows) => {
    let tempArray = rows.map((item) => {
      return { ...item, isChecked: false };
    });
    setChecked(tempArray);
  };

  useEffect(() => {
    if (rows && rows.length > 0) {
      addCheckInRows(rows);
    }
  }, [rows, rows.length]);

  // Function to filter rows based on the search value
  const filterRows = (value) => {
    const filteredData = rows.filter((row) => {
      return Object.values(row).some((val) =>
        String(val).toLowerCase().includes(value.toLowerCase())
      );
    });
    setFilteredRows(filteredData);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    filterRows(value); // Update filtered rows when search value changes
  };
  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);
  return (
    <TableContainer component={Paper} sx={{ maxHeight }}>
      <Table
        sx={{ minWidth: 500, position: "relative" }}
        aria-label="simple table"
      >
        {loader ? (
          <div className="custom-table-loader-wrapper">
            <Spinner />
          </div>
        ) : (
          []
        )}

        {/* Search Bar Row (if searchBar is true) */}
        {searchBar && (
          <TableHead>
            <TableRow>
              {(isSelection || isCheckBox) && <CustomColumnCell />}
              <CustomColumnCell>
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleSearchChange}
                  placeholder="Search..."
                  style={{
                    width: "100%",
                    padding: "4px",
                    borderRadius: "5px",
                    border: "2px solid #e9c451",
                    backgroundColor: "#CCCCCC",
                  }}
                />
              </CustomColumnCell>
              {Array(
                columns.length +
                  (isActions ? 1 : 0) -
                  (isSelection || isCheckBox ? 1 : 0)
              )
                .fill(null)
                .map((_, index) => (
                  <CustomColumnCell key={index} />
                ))}
            </TableRow>
          </TableHead>
        )}

        <TableHead>
          <TableRow>
            {isCheckBox && (
              <CustomColumnCell padding="checkbox">
                <CustomCheckBox
                  color="primary"
                  checked={checked.every((item) => item.isChecked === true)}
                  onChange={handleColumnCheck}
                />
              </CustomColumnCell>
            )}
            {isSelection && (
              <CustomColumnCell padding="checkbox"></CustomColumnCell>
            )}
            {columns.map((col, index) => (
              <CustomColumnCell key={index}>{col.name}</CustomColumnCell>
            ))}
            {isActions && <CustomColumnCell>Actions</CustomColumnCell>}
            {isApprovals && <CustomColumnCell>Approvals</CustomColumnCell>}
          </TableRow>
        </TableHead>

        <TableBody style={{ position: "relative" }}>
          {filteredRows.length > 0 ? (
            filteredRows.map((row, index) => (
              <TableRow key={index}>{displayRow(row, index)}</TableRow>
            ))
          ) : (
            <TableRow>
              <CustomCell colSpan={6}>
                {!rows.length ? emptyDisplayText : "No matches"}
              </CustomCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
